<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container
      fluid
      class="px-6"
    >
      <div>
        <h1 class="display-1 font-weight-bold">Détails du produit</h1>
        <!-- <p class="subtitle-1 grey--text"><strong>ID:</strong> A1234567890</p> -->
      </div>
      <v-tabs
        class="mt-6"
        v-model="tab"
        background-color="primary"
        center-active
        dark
        @change="tabChanged(tab)"
      >
        <v-tab href="#general-info">{{ $t("products.general_info") }}</v-tab>
        <v-tab href="#pricing">{{ $t("products.pricing") }}
          <v-icon color="" v-if="product.promotion" >mdi-sale</v-icon>
        </v-tab>
        <v-tab href="#marketing-info">{{
          $t("products.marketing_info")
        }}</v-tab>
        <v-tab href="#hubs">{{ $t("hubs") }}</v-tab>
        <v-tab
          v-if="$admin.can('lot-list')"
          href="#lots"
        >
          {{ $t("batches") }}
        </v-tab>
        <v-btn
          outlined
          small
          class="my-auto ml-auto mr-2 rounded-0"
          v-if="$admin.can('lot-export') && tab == 'lots'"
          @click="getCSV()"
          :loading="is_loading_csv"
          :disabled="is_loading_csv"
        >
          {{ $t("export_csv") }}
        </v-btn>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          :key="1"
          :value="'general-info'"
        >
          <!-- Genenral Information Section -->
          <div
            class="d-flex flex-column flex-md-row"
            style="gap: 50px"
          >
            <!-- General Information - Left Section -->
            <div
              class="flex-grow-1 mt-8"
              style="min-width: 45%"
            >
              <h4 class="mt-2">{{ $t("products.information") }}</h4>
              <products-view-loader v-if="!loaded" />
              <div
                v-if="loaded"
                class="mt-8 font-weight-medium"
              >
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("id_old_new") }}</span>
                  <span class="text-capitalize">
                    {{
                      product?.generated_code
                        ? $t("new_id", { id: product.generated_code })
                        : product?.code
                        ? $t("old_id", { id: product.code })
                        : "/"
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.name") }}</span>
                  <span class="text-capitalize">
                    {{ product.name }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.type") }}</span>
                  <span class="text-capitalize">
                    {{ product.type }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.barcode") }}</span>
                  <span>{{ product.barcodes[0]?.code }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.shelf") }}</span>
                  <span>
                    {{ currentShelf?.name }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.category") }}</span>
                  <span>{{ currentRoot?.name }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.sub_category") }}</span>
                  <span>{{ currentCategory?.name }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.brand") }}</span>
                  <span>{{ product.brand?.name }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.primary_supplier") }}</span>
                  <span>{{ mainProvider }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <div class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.secondary_supplier") }}</span>
                  <div>
                    <span
                      v-for="(secondaryProvider, index) in secondaryProviders"
                      v-bind:key="index"
                    >
                      {{ secondaryProvider }}
                    </span>
                  </div>
                </div>
                <hr style="border-top: 1px solid #d3d3d3" />
              </div>
            </div>
            <!-- /General Information - Left Section -->
            <!-- General Information - Right Section -->
            <div
              class="flex-grow-1 mt-8"
              style="min-width: 45%"
            >
              <h4 class="mt-2">{{ $t("products.item_information") }}</h4>
              <products-view-loader v-if="!loaded" />
              <div
                v-if="loaded"
                class="mt-8 font-weight-medium"
              >
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.Item_weight") }}</span>
                  <span>
                    {{ product.product_info?.item_net_weight }}
                    {{ product.product_info?.item_net_weight_unit }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.item_gross_weight") }}</span>
                  <span>
                    {{ product.product_info?.item_gross_weight }}
                    {{ product.product_info?.item_gross_weight_unit }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.Item_volume") }}</span>
                  <span>
                    {{ product.product_info?.item_net_volume }}
                    {{ product.product_info?.item_net_volume_unit }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.item_gross_volume") }}</span>
                  <span>
                    {{ product.product_info?.item_gross_volume }}
                    {{ product.product_info?.item_gross_volume_unit }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.unit_package") }}</span>
                  <span>
                    {{ product.product_info?.package_units }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.package_width") }}</span>
                  <span>
                    {{ product.product_info?.package_width }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.package_depth") }}</span>
                  <span>
                    {{ product.product_info?.package_depth }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.package_height") }}</span>
                  <span>
                    {{ product.product_info?.package_height }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.package_net_weight") }}</span>
                  <span>
                    {{ product.product_info?.package_net_weight }}
                    {{ product.product_info?.package_net_weight_unit }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.package_gross_weight") }}</span>
                  <span>
                    {{ product.product_info?.package_gross_weight }}
                    {{ product.product_info?.package_gross_weight_unit }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("package_palette") }}</span>
                  <span>
                    {{ product.product_info?.palette_units }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.palette_width") }}</span>
                  <span>
                    {{ product.product_info?.palette_width }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.palette_depth") }}</span>
                  <span>
                    {{ product.product_info?.palette_depth }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.palette_height") }}</span>
                  <span>
                    {{ product.product_info?.palette_height }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.palette_weight") }}</span>
                  <span>
                    {{ product.product_info?.package_net_weight }}
                    {{ product.product_info?.package_net_weight_unit }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.palette_gross_weight") }}</span>
                  <span>
                    {{ product.product_info?.palette_gross_weight }}
                    {{ product.product_info?.palette_gross_weight_unit }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.point_of_supply") }}</span>
                  <span class="text-capitalize">
                    {{ product.point_of_supply }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.imported") }}</span>
                  <span class="text-capitalize">
                    {{ !!product.imported }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
              </div>
            </div>
            <!-- General Information - Right Section -->
          </div>
          <!-- /General Information Section -->
        </v-tab-item>
        <v-tab-item
          :key="2"
          :value="'pricing'"
        >
          <div
            class="mt-10 d-flex"
            style="gap: 50px"
          >
            <div
              class="flex-grow-1"
              style="max-width: 700px"
            >
              <h4 class="mt-2">{{ $t("products.pricing_details") }}</h4>
              <products-view-loader v-if="!loaded" />
              <div
                v-if="loaded"
                class="mt-8 font-weight-medium"
              >
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("products.purchase_price_ht") }}</span>
                  <span>
                    {{
                      product.purchase_price_ht &&
                      product.purchase_tva !== undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: user.currency ? user.currency :'usd',
                      }).format(product.purchase_price_ht)
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("products.purchase_tva") }}</span>

                  <span>
                    {{ product.purchase_tva + "%" }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("products.purchase_price_ttc") }}</span>
                  <span>
                    {{
                      product.purchase_price_ht &&
                      product.purchase_tva !== undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: user.currency ? user.currency :'usd',
                      }).format(product.purchase_price)
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.selling_price_ht") }}</span>
                  <span>
                    {{
                      product.price &&
                      product.tva != undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: user.currency ? user.currency :'usd',
                      }).format(product.price_ht)
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("products.selling_tva") }}</span>
                  <span>{{ product.tva && product.tva + "%" }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("products.selling_price_ttc") }}</span>

                  <span>
                    {{
                      product.price &&
                      product.purchase_tva != undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: user.currency ? user.currency :'usd',
                      }).format(product.price)
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.margin") }}</span>
                  <span>
                    {{
                      product.purchase_price_ht &&
                      product.purchase_tva != undefined &&
                      product.price &&
                      calculateMarge() + "%"
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("products.margin_objective") }}</span>
                  <span
                    v-bind:class="{
                      'light-green--text':
                        calculateMarge() >= this.currentShelf?.margin,
                      'red--text': calculateMarge() < this.currentShelf?.margin,
                    }"
                    >{{ this.currentShelf?.margin + "%" }}</span
                  >
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
              </div>
            </div>
            <div
              class="flex-grow-1"
              style="max-width: 700px"
              v-if="product.promotion"
            >
              <h4 class="mt-2">{{ $t("promotions.title") }}</h4>
              <products-view-loader v-if="!loaded" />
              <div
                v-if="loaded"
                class="mt-8 font-weight-medium"
              >
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("promotions.name") }}</span>
                  <span>
                    {{
                      product.promotion.name
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("promotions.discount") }}</span>

                  <span>
                    {{ product.promotion.discount + "%" }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("promotions.validity") }}</span>
                  <span>
                    {{
                      product.promotion.end_date
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item
          disabled
          :key="3"
          :value="'marketing-info'"
        >
          <div
            class="mt-10"
            style="max-width: 700px"
          >
            <h4>{{ $t("products.marketing_details") }}</h4>
            <products-view-loader v-if="!loaded" />
            <div
              v-if="loaded"
              class="mt-8 font-weight-medium"
            >
              <p class="d-flex justify-space-between my-4">
                <span>{{ $t("products.marketing_designation") }}</span>
                <span class="text-capitalize">
                  {{ product.name_marketing }}
                </span>
              </p>

              <p class="d-flex justify-space-between my-4">
                <span>{{ $t("products.max_order_quantity") }}</span>
                <span class="text-capitalize">
                  {{ maxOrderQte }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="d-flex justify-space-between align-center my-2">
                <span>{{ $t("products.express_status") }}</span>
                <v-switch
                  disabled
                  v-model="product.express_status"
                ></v-switch>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="mt-4">
                <span>{{ $t("products.short_description") }}</span>
                <v-textarea
                  disabled
                  outlined
                  class="my-4"
                  v-model="product.short_description"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="my-2">
                <span>{{ $t("products.description") }}</span>
                <vue-editor
                  disabled
                  class="my-4"
                  v-model="product.description"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="my-2">
                <span>{{ $t("products.special_categories") }}</span>
                <p>
                  {{ specialCategories }}
                </p>
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="d-flex justify-space-between align-center my-2">
                <span>{{ $t("products.image") }}</span>
                <img
                  :src="product?.image?.url"
                  style="height: 100px"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
            </div>
          </div>
        </v-tab-item>
        <v-tab-item
          :key="4"
          :value="'hubs'"
        >
          <view-hubs-tab
            :product="product"
            :loaded="loaded"
          ></view-hubs-tab>
        </v-tab-item>
        <v-tab-item
          :key="5"
          :value="'lots'"
        >
          <view-lots-tab
            :product="product"
            :isActive="'lots' == tab"
          ></view-lots-tab>
        </v-tab-item>
      </v-tabs-items>
      <div class="mt-10">
        <v-btn
          text
          @click="returnToList()"
        >
          {{ $t("back") }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";
import "./form-style.css";
import { Product } from "./product.class";
import { validationRules } from "@/mixins/validationRules";
import _ from "lodash";
import productService from "@/store/services/product-service";

import { VueEditor } from "vue2-editor";
import ProductsViewLoader from "./ProductsViewLoader";
import ViewHubsTab from "./tabs/ViewHubsTab";
import ViewLotsTab from "./tabs/ViewBatchesTab";

export default {
  mixins: [validationRules],
  components: {
    VueEditor,
    ProductsViewLoader,
    ViewHubsTab,
    ViewLotsTab,
  },

  data() {
    return {
      tab: null,
      valid: false,
      loaded: false,
      product: new Product(),
      productEntity: null,
      currentRoot: null,
      currentShelf: null,
      currentCategory: null,
      mainProvider: null,
      secondaryProviders: [],
      content: "<p>Product Description</p>",
      volumeUnits: ["ml", "cl", "L", "mm3", "cm3", "m3"],
      weightUnits: ["g", "kg"],
      productTypes: [
        {
          name: "Simple",
          value: "simple",
        },
        {
          name: "Package",
          value: "package",
        },
        {
          name: "Bundle",
          value: "bundle",
        },
      ],
      checkingBarcode: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoadingShelves: "categories/isLoadingCategories",
      shelves: "categories/listShelves",
      categories: "categories/listRoots",
      leafs: "categories/listLeafs",
      brands: "brands/activeBrands",
      providers: "providers/listProviders",
      deliveryTypes: "deliveryTypes/list",
      user: "users/editedUser",
    }),

    lotFilters: {
      get() {
        return this.$store.state.lots.filters;
      },
      set(value) {
        this.$store.commit("lots/SET_FILTERS", value);
      },
    },

    currentTab: {
      get() {
        return this.$store.state.transferProducts.currentTab;
      },
      set(value) {
        this.$store.commit("transferProducts/CURRENT_TAB", value);
      },
    },
    specialCategories() {
      const names = this.product?.special_categories
        ?.map((item) => item.name)
        .filter(Boolean);
      return names?.length ? names.join(", ") : "-";
    },
    maxOrderQte() {
      const qte = this.product?.max_order_qte;
      console.log(qte);
      if (qte === "" || qte === null || qte === undefined) return "-";
      if (qte === 0 || qte === "0") return "No limit";
      return qte;
    },
  },
  mounted() {
    const editedUserJSON = localStorage.getItem("user");
    if (editedUserJSON) {
      const editedUser = JSON.parse(editedUserJSON);
      this.$store.commit("users/EDITED_USER", editedUser);
    }
  },
  methods: {
    loadProductTypes() {},
    loadShelves() {
      return this.$store.dispatch("categories/listShelves");
    },
    loadCategories() {
      return this.$store.dispatch("categories/listRoots");
    },
    loadSubCategories() {
      return this.$store.dispatch("categories/listLeafs");
    },
    loadBrands() {
      return this.$store.dispatch("brands/list");
    },
    loaderProviders() {
      return this.$store.dispatch("providers/list");
    },
    loadDeliveryTypes() {
      return this.$store.dispatch("deliveryTypes/list");
    },
    init() {
      this.loadProductTypes();
      this.loadShelves();
      this.loadCategories();
      this.loadSubCategories();
      this.loadBrands();
      this.loaderProviders();
      this.loadDeliveryTypes();
    },
    returnToList() {
      this.$router.push("/setup/products");
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addProvider() {
      if (this.product.secondary_providers.length >= 3) {
        this.$swal("", "Provider limit reached", "info");
      } else {
        this.product.secondary_providers.push({
          id: null,
          status: false,
        });
      }
    },
    calculateMarge() {
      return Number(
        (100 * (this.product.price_ht - this.product.purchase_price_ht)) /
          this.product.purchase_price_ht
      ).toFixed(2);
    },
    removeProvider(providerIndex) {
      this.product.secondary_providers =
        this.product.secondary_providers.filter(
          (value, index) => index !== providerIndex
        );
    },
    generateBarCode(index) {
      this.product.barcodes[index].code = parseInt(
        "200" + Math.floor(Math.random() * 900000000) + 1
      );
    },
    addBarCode() {
      if (this.product.barcodes.length >= 3) {
        this.$swal("", "Barcode limit reached", "info");
      } else {
        this.product.barcodes.push({
          is_main: false,
          code: null,
        });
      }
    },
    removeBarCode(barcodeIndex) {
      this.product.barcodes = this.product.barcodes.filter(
        (value, index) => index !== barcodeIndex
      );
    },
    shelfChanged(value) {
      this.currentRoot = null;
      this.currentCategories = this.categories.filter(
        (category) => category.parent_id === value
      );
    },
    rootChanged(value) {
      this.product.category_id = null;
      this.currentSubCategories = this.leafs.filter(
        (leaf) => leaf.parent_id === value
      );
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    debounceCheckBarcode: _.debounce(function (e, index) {
      this.checkBarCode(e, index);
    }, 1000),
    checkBarCode() {
      this.checkingBarcode = true;
      setTimeout(() => {
        this.checkingBarcode = false;
      }, 2000);
    },

    async getCSV() {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("lots/exportCSV", this.lotFilters)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "product_batches.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
        });
    },
    async tabChanged(index) {
      this.currentTab = index;
    },
  },
  watch: {
    product: function (newProduct) {
      const subCategory = this.leafs.find(
        (category) => category.id == newProduct?.category.id
      );
      this.currentCategory = subCategory;
      const root = this.categories.find(
        (category) => category.id == subCategory?.parent_id
      );
      this.currentRoot = root;
      const shelf = this.shelves.find((shelf) => shelf.id == root?.parent_id);
      this.currentShelf = shelf;
      const { name } = this.providers.find(
        (provider) => provider.id === newProduct?.main_provider?.id
      );
      this.mainProvider = name;
      for (let secondaryProvider of newProduct.secondary_providers) {
        const { name } = this.providers.find(
          (provider) => provider.id === secondaryProvider?.id
        );
        this.secondaryProviders.push(name);
      }
    },
  },
  async created() {
    this.tab = this.currentTab;
    this.lotFilters.product_id = this.$route.params.id;
    this.init();
    this.productEntity = await productService.show({
      id: this.$route.params.id,
    });
    this.product = this.productEntity;
    this.loaded = true;
  },
};
</script>
